var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pane_container" },
    [
      _c(
        "el-descriptions",
        {
          staticClass: "box_description",
          staticStyle: { "margin-bottom": "20px" },
          attrs: { title: "基本信息", column: 3 },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "设备编号" } }, [
            _vm._v("kooriookami"),
          ]),
          _c("el-descriptions-item", { attrs: { label: "软件版本号" } }, [
            _vm._v("18100000000"),
          ]),
          _c("el-descriptions-item", { attrs: { label: "安装地点" } }, [
            _vm._v("苏州市"),
          ]),
          _c("el-descriptions-item", { attrs: { label: "经度坐标" } }),
          _c("el-descriptions-item", { attrs: { label: "纬度坐标" } }),
          _c("el-descriptions-item", { attrs: { label: "用水定额(m³)" } }),
          _c("el-descriptions-item", { attrs: { label: "灌溉亩数(亩)" } }),
          _c("el-descriptions-item", { attrs: { label: "作物类型" } }),
          _c("el-descriptions-item", { attrs: { label: "所属机构" } }),
          _c("el-descriptions-item", { attrs: { label: "设备安装图片" } }),
          _c("el-descriptions-item", { attrs: { label: "设备描述" } }),
        ],
        1
      ),
      _vm.deviceType == 1
        ? _c(
            "el-descriptions",
            {
              staticClass: "box_description",
              attrs: { title: "测控一体化闸门信息", column: 3 },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "闸板宽(mm)" } }, [
                _vm._v("kooriookami"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "闸板高(mm)" } }, [
                _vm._v("18100000000"),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "闸板最大行程(mm)" } },
                [_vm._v("苏州市")]
              ),
              _c("el-descriptions-item", { attrs: { label: "闸前设备" } }),
              _c("el-descriptions-item", { attrs: { label: "闸后设备" } }),
              _c("el-descriptions-item", { attrs: { label: "供电方式" } }),
              _c("el-descriptions-item", { attrs: { label: "电机电压(V)" } }),
              _c("el-descriptions-item", { attrs: { label: "电机功率(W)" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceType == 2
        ? _c(
            "el-descriptions",
            {
              staticClass: "box_description",
              attrs: { title: "箱式超声波明渠流量计", column: 3 },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "测箱宽h(mm)" } }, [
                _vm._v("kooriookami"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "测箱高w(mm)" } }, [
                _vm._v("18100000000"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "换能器数量n" } }, [
                _vm._v("苏州市"),
              ]),
              _c("el-descriptions-item", {
                attrs: { label: "最下层换能器距离底板距(mm)" },
              }),
              _c("el-descriptions-item", {
                attrs: { label: "最上层换能器距离顶板距(mm)" },
              }),
              _c("el-descriptions-item", {
                attrs: { label: "换能器分布规律" },
              }),
              _c("el-descriptions-item", { attrs: { label: "换能器频率" } }),
              _c("el-descriptions-item", { attrs: { label: "供电方式" } }),
              _c("el-descriptions-item", {
                attrs: { label: "水位传感器安装方式" },
              }),
              _c("el-descriptions-item", {
                attrs: { label: "水位传感器数量" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceType == 3
        ? _c(
            "el-descriptions",
            {
              staticClass: "box_description",
              attrs: { title: "开放式超声波明渠流量计", column: 3 },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "渠道顶宽(mm)" } }, [
                _vm._v("kooriookami"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "渠道底宽(mm)" } }, [
                _vm._v("18100000000"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "渠道深度(mm)" } }, [
                _vm._v("苏州市"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "第一T型宽(mm)" } }),
              _c("el-descriptions-item", { attrs: { label: "第二T型宽(mm)" } }),
              _c("el-descriptions-item", { attrs: { label: "第一T型高(mm)" } }),
              _c("el-descriptions-item", { attrs: { label: "第二T型高(mm)" } }),
              _c("el-descriptions-item", { attrs: { label: "声道数" } }),
              _c("el-descriptions-item", { attrs: { label: "分布规则" } }),
              _c("el-descriptions-item", {
                attrs: { label: "第1~N个声道的安装高度(mm)" },
              }),
              _c("el-descriptions-item", {
                attrs: { label: "水位传感器安装高度(mm)" },
              }),
              _c("el-descriptions-item", { attrs: { label: "供电方式" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceType == 4
        ? _c(
            "el-descriptions",
            {
              staticClass: "box_description",
              attrs: { title: "无线式超声波明渠流量计", column: 3 },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "渠道顶宽(mm)" } }, [
                _vm._v("kooriookami"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "渠道底宽(mm)" } }, [
                _vm._v("18100000000"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "渠道深度(mm)" } }, [
                _vm._v("苏州市"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "第一T型宽(mm)" } }),
              _c("el-descriptions-item", { attrs: { label: "第二T型宽(mm)" } }),
              _c("el-descriptions-item", { attrs: { label: "第一T型高(mm)" } }),
              _c("el-descriptions-item", { attrs: { label: "第二T型高(mm)" } }),
              _c("el-descriptions-item", { attrs: { label: "声道数" } }),
              _c("el-descriptions-item", { attrs: { label: "分布规则" } }),
              _c("el-descriptions-item", {
                attrs: { label: "第1~N个声道的安装高度(mm)" },
              }),
              _c("el-descriptions-item", {
                attrs: { label: "水位传感器安装高度(mm)" },
              }),
              _c("el-descriptions-item", { attrs: { label: "供电方式" } }),
              _c("el-descriptions-item", {
                attrs: { label: "淤积检测点数量" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceType == 5
        ? _c(
            "el-descriptions",
            {
              staticClass: "box_description",
              attrs: { title: "巴歇尔槽明渠流量计", column: 3 },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "槽号" } }),
              _c("el-descriptions-item", { attrs: { label: "B值(mm)" } }),
              _c("el-descriptions-item", {
                attrs: { label: "水位传感器安装高度(mm)" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceType == 6
        ? _c(
            "el-descriptions",
            {
              staticClass: "box_description",
              attrs: { title: "水位流量计", column: 3 },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "水位传感器安装高度(mm)" } },
                [_vm._v("kooriookami")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceType == 7
        ? _c(
            "el-descriptions",
            {
              staticClass: "box_description",
              attrs: { title: "水位计", column: 3 },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "水位计安装高度(mm)" } },
                [_vm._v("kooriookami")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }