<template>
  <div class="pane_container">
    <el-descriptions title="基本信息" :column="3" class="box_description" style="margin-bottom: 20px;">
      <el-descriptions-item label="设备编号">kooriookami</el-descriptions-item>
      <el-descriptions-item label="软件版本号">18100000000</el-descriptions-item>
      <el-descriptions-item label="安装地点">苏州市</el-descriptions-item>
      <el-descriptions-item label="经度坐标"></el-descriptions-item>
      <el-descriptions-item label="纬度坐标"></el-descriptions-item>
      <el-descriptions-item label="用水定额(m³)"></el-descriptions-item>
      <el-descriptions-item label="灌溉亩数(亩)"></el-descriptions-item>
      <el-descriptions-item label="作物类型"></el-descriptions-item>
      <el-descriptions-item label="所属机构"></el-descriptions-item>
      <el-descriptions-item label="设备安装图片"></el-descriptions-item>
      <el-descriptions-item label="设备描述"></el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="deviceType == 1" title="测控一体化闸门信息" :column="3" class="box_description">
      <el-descriptions-item label="闸板宽(mm)">kooriookami</el-descriptions-item>
      <el-descriptions-item label="闸板高(mm)">18100000000</el-descriptions-item>
      <el-descriptions-item label="闸板最大行程(mm)">苏州市</el-descriptions-item>
      <el-descriptions-item label="闸前设备"></el-descriptions-item>
      <el-descriptions-item label="闸后设备"></el-descriptions-item>
      <el-descriptions-item label="供电方式"></el-descriptions-item>
      <el-descriptions-item label="电机电压(V)"></el-descriptions-item>
      <el-descriptions-item label="电机功率(W)"></el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="deviceType == 2" title="箱式超声波明渠流量计" :column="3" class="box_description">
      <el-descriptions-item label="测箱宽h(mm)">kooriookami</el-descriptions-item>
      <el-descriptions-item label="测箱高w(mm)">18100000000</el-descriptions-item>
      <el-descriptions-item label="换能器数量n">苏州市</el-descriptions-item>
      <el-descriptions-item label="最下层换能器距离底板距(mm)"></el-descriptions-item>
      <el-descriptions-item label="最上层换能器距离顶板距(mm)"></el-descriptions-item>
      <el-descriptions-item label="换能器分布规律"></el-descriptions-item>
      <el-descriptions-item label="换能器频率"></el-descriptions-item>
      <el-descriptions-item label="供电方式"></el-descriptions-item>
      <el-descriptions-item label="水位传感器安装方式"></el-descriptions-item>
      <el-descriptions-item label="水位传感器数量"></el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="deviceType == 3" title="开放式超声波明渠流量计" :column="3" class="box_description">
      <el-descriptions-item label="渠道顶宽(mm)">kooriookami</el-descriptions-item>
      <el-descriptions-item label="渠道底宽(mm)">18100000000</el-descriptions-item>
      <el-descriptions-item label="渠道深度(mm)">苏州市</el-descriptions-item>
      <el-descriptions-item label="第一T型宽(mm)"></el-descriptions-item>
      <el-descriptions-item label="第二T型宽(mm)"></el-descriptions-item>
      <el-descriptions-item label="第一T型高(mm)"></el-descriptions-item>
      <el-descriptions-item label="第二T型高(mm)"></el-descriptions-item>
      <el-descriptions-item label="声道数"></el-descriptions-item>
      <el-descriptions-item label="分布规则"></el-descriptions-item>
      <el-descriptions-item label="第1~N个声道的安装高度(mm)"></el-descriptions-item>
      <el-descriptions-item label="水位传感器安装高度(mm)"></el-descriptions-item>
      <el-descriptions-item label="供电方式"></el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="deviceType == 4" title="无线式超声波明渠流量计" :column="3" class="box_description">
      <el-descriptions-item label="渠道顶宽(mm)">kooriookami</el-descriptions-item>
      <el-descriptions-item label="渠道底宽(mm)">18100000000</el-descriptions-item>
      <el-descriptions-item label="渠道深度(mm)">苏州市</el-descriptions-item>
      <el-descriptions-item label="第一T型宽(mm)"></el-descriptions-item>
      <el-descriptions-item label="第二T型宽(mm)"></el-descriptions-item>
      <el-descriptions-item label="第一T型高(mm)"></el-descriptions-item>
      <el-descriptions-item label="第二T型高(mm)"></el-descriptions-item>
      <el-descriptions-item label="声道数"></el-descriptions-item>
      <el-descriptions-item label="分布规则"></el-descriptions-item>
      <el-descriptions-item label="第1~N个声道的安装高度(mm)"></el-descriptions-item>
      <el-descriptions-item label="水位传感器安装高度(mm)"></el-descriptions-item>
      <el-descriptions-item label="供电方式"></el-descriptions-item>
      <el-descriptions-item label="淤积检测点数量"></el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="deviceType == 5" title="巴歇尔槽明渠流量计" :column="3" class="box_description">
      <el-descriptions-item label="槽号"></el-descriptions-item>
      <el-descriptions-item label="B值(mm)"></el-descriptions-item>
      <el-descriptions-item label="水位传感器安装高度(mm)"></el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="deviceType == 6" title="水位流量计" :column="3" class="box_description">
      <el-descriptions-item label="水位传感器安装高度(mm)">kooriookami</el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="deviceType == 7" title="水位计" :column="3" class="box_description">
      <el-descriptions-item label="水位计安装高度(mm)">kooriookami</el-descriptions-item>
    </el-descriptions>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  export default {
    name: 'jbxx',//基本信息
    components: {
      
    },
    props: {
      //设备类型
      // deviceType:[String, Object, Array],
      visible:{
        default:false
      },
      //是否是详情，详情进入不可修改
      isCheckDetail:{
        default:false
      },
    },
    directives: {
      
    },
    data() {
      return {
        deviceType:1,
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      
    },
    methods: {
      
    },
  }

</script>

<style lang="scss" scoped>
  .pane_container{
    background: #fff;
    padding: 10px;
    .box_description {
      ::v-deep .el-descriptions__title {
        font-size: 14px;
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
      }
      ::v-deep .el-descriptions__header {
        border-bottom: 1px dashed #ddd;
      }
    }
  }
</style>
